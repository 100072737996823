<template>
  <BaseFormInput v-bind="$props">
    <v-select
      ref="input"
      v-model="model"
      class="invisible"
      :items="items"
      item-text="name"
      item-value="href"
      :label="fieldStyle.labelAbove ? null : label"
      :rules="[rules.required, rules.schema]"
      :placeholder="placeholder"
      :hide-details="description || errors ? 'auto' : false"
      :readonly="readonly"
      @blur="finishEditing"
      :color="color"
      :disabled="disabled"
      :loading="loading"
      v-bind="fieldStyle"
      clearable
      @click:clear="clear"
    />
  </BaseFormInput>
</template>

<script>
import { formFields } from '@/mixins/formFields.js'
import BaseFormInput from '@/components/form/BaseFormInput.vue'
import externalModel from '@/mixins/externalModel'
import resourceInputMixin from '@/mixins/resourceInputMixin'

export default {
  mixins: [formFields, externalModel, resourceInputMixin],
  data() {
    return {
      items: [],
      loading: false
    }
  },
  mounted() {
    this.loadResources()
  },
  computed: {
    model: {
      get() {
        return this.externalModel?.href
      },
      set(newVal) {
        if (newVal == null) {
          this.externalModel = null
          return
        }
        this.externalModel = { href: newVal }
      }
    },
  },
  methods: {
    clear() {
      this.externalModel = null
      this.finishEditing()
    },
    finishEditing() {
      this.$emit('blur')
    }
  },
  components: {
    BaseFormInput
  }
}
</script>
