<template>
  <div class="d-flex flex-column ma-6">
    <v-sheet rounded elevation="2" color="white" class="profile pa-10">

    <!-- <v-card class="pa-5 flex-shrink-1" :class="{'content-card': !mobile, 'mt-5': !mobile}"> -->
      <div class="text-h6">{{$t('createApiKey.title')}}</div>
      <v-divider class="mt-2 mb-4"></v-divider>
      <div class="mt-5 text-subtitle-1">{{$t('createApiKey.explanation')}}</div>
      <AsyncFetcher class="mt-5" :promise="listApiKeyPromise" :initialValue="[]">
        <template #default="{data}">
          <v-list>
            <v-list-item v-for="(apiKey, index) in data" :key="index" class="d-flex" link="">
              <div class="mr-3">{{apiKey.name}}</div>
              <v-chip v-if="apiKey.role" small outlined color="primary">{{roleLabels[apiKey.role]}}</v-chip>
              <v-btn class="ml-auto" color="red" depressed text small @click="deleteCredentials(index)">{{$t('dialogs.deleteButton')}}</v-btn>
            </v-list-item>
          </v-list>
        </template>
      </AsyncFetcher>
      <SingleInputDialog
          v-model="dialogModel"
          :title="$t('createApiKey.dialogTitle')"
          :text="$t('createApiKey.dialogText')"
          :okButtonLabel="$t('createApiKey.dialogOkButtonLabel')"
          :okHandler="createApiKey"
          max-width="400"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!canAddCredentials"
            class="mt-5"
            color="primary"
            depressed
            v-on="on"
            :attrs="attrs"
          >{{$t('createApiKey.buttonLabel')}}</v-btn>
        </template>
        <v-select
          v-model="selectedRole"
          class="mt-3"
          :label="$t('createApiKey.roleSelectLabel')"
          :items="roles"
          :rules="rules.required"
          dense
        />
      </SingleInputDialog>
      <NewApiCredentialsDialog v-model="newApiCredentialsDialog" :credentials="createdCredentials" />
    </v-sheet>
  </div>
</template>

<script>
import SingleInputDialog from '@/components/SingleInputDialog.vue'
import AsyncFetcher from '@/components/AsyncFetcher.vue'
import NewApiCredentialsDialog from '@/components/user/NewApiCredentialsDialog.vue'

import {textFieldRules} from '@/mixins/textFieldRules.js'
import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'

export default {
  mixins: [textFieldRules],
  data() {
    return {
      dialogModel: false,
      listApiKeyPromise: undefined,
      createdCredentials: undefined,
      newApiCredentialsDialog: false,
      selectedRole: 'admin'
    }
  },
  mounted() {
    this.loadApiKeys()
  },
  computed: {
    apiKeys() {
      return this.$store.state.user.apiKeys
    },
    mobile() {
      return this.$vuetify.breakpoint.mobile
    },
    roles() {
      return [
        {
          text: this.$t('createApiKey.adminDescription'),
          value: 'admin'
        },
        {
          text: this.$t('createApiKey.readerDescription'),
          value: 'reader'
        }
      ]
    },
    roleLabels() {
      return {
        admin: this.$t('createApiKey.adminLabel'),
        reader: this.$t('createApiKey.readerLabel')
      }
    },
    user() {
      return this.$store.state.user?.user
    },
    canAddCredentials() {
      return hasPermission(this.user, [PERMISSIONS.addAccessCredentials])
    }
  },
  methods: {
    loadApiKeys() {
      this.listApiKeyPromise = this.$store.dispatch('loadApiKeys')
    },
    createApiKey(name) {
      return this.$store.dispatch('createApiKey', {name, role: this.selectedRole})
        .then(credential => {
          this.loadApiKeys()
          this.createdCredentials = credential
          this.newApiCredentialsDialog = true
          this.$intercom?.trackEvent( 'User created a api key' )
        })
    },
    deleteCredentials(index) {
      const credentials = this.apiKeys[index]
      this.$store.dispatch('deleteCredentials', credentials)
    }
  },
  components: {
    SingleInputDialog,
    AsyncFetcher,
    NewApiCredentialsDialog
  }
}
</script>

<style scoped>
.api-keys-container {
  background: #F7F7F7;
}

.content-card {
  max-width: 80%;
}
</style>
