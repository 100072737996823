import HalObject from '@/store/models/HalObject'
import store from '../../../store'

export default class ExternalFlowTrigger extends HalObject {
  constructor(data) {
    super(data)
    this.name = data.name
    this.type = data.type
    this.uri = data.uri
    this.redirectUri = data.redirectUri

  }

  get eventType() {
    return this.type
  }

  runExternalHook (payload) {
    return store().dispatch('AGFlowRunExternalHook', { webhook: this, payload})
  }

}