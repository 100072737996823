<template>
  <div ref="markdownInput" class="textarea"></div>
</template>

<script>
import MarkdownEditor from '@/editor/MarkdownEditor'
import externalModel from '@/mixins/externalModel'

import 'prosemirror-menu/style/menu.css'
import 'prosemirror-example-setup/style/style.css'

  export default {
    mixins: [externalModel],
    data() {
      return {
        editor: undefined
      }
    },
    watch: {
      externalModel: {
        immediate: true,
        async handler(newVal) {
          if (this.editor != null) {
            this.editor.setContent(newVal)
            return
          }
          await this.$nextTick()
          this.editor = new MarkdownEditor(
            this.$refs.markdownInput,
            newVal ?? '',
            {
              blur: () => {
                this.focused = false
                this.externalModel = this.editor.content
                this.finishEditing()
              },
            }
          )
        }
      }
    },
    methods: {
      finishEditing() {
        this.$emit('blur')
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>

<style>

.ProseMirror-example-setup-style {
  min-height: 200px;
  color: #484848 !important;
  font-size: 1em;
  outline: none;
}

.ProseMirror {
  padding: 8px;
}

.ProseMirror-menubar {
  background: #F8F8F8;
  padding: 8px;
  padding-right: 32px;
  border: none;
}

/* Hiding Code toggle */
.ProseMirror-menubar > *:nth-child(3){
    display: none;
}

/* Hiding Code Menu item in Type... menu */
.ProseMirror-menu-dropdown-menu > *:nth-child(2){
    display: none;
}

/* Hiding Header levels 4 to 6 */
.ProseMirror-menu-submenu > *:nth-child(n+4):nth-child(-n+6){
    display: none;
}

.ProseMirror-menubar > *:nth-child(n+5):nth-child(-n+6){
    display: none;
}

.ProseMirror-menubar > *:nth-child(n+8):nth-child(-n+11){
    display: none;
}

.ProseMirror-menubar > *:nth-child(n+12):nth-child(-n+13){
    display: inline-block !important;
}

.ProseMirror-menubar > *:nth-child(n+12):nth-child(-n+13) > div{
    display: inline-block !important;
}

.ProseMirror-menubar > *:nth-child(n+13):nth-child(-n+17){
    display: none;
}

.ProseMirror-prompt h5 {
  font-weight: bold;
}

.ProseMirror-prompt div {
  margin-top: 4px;
  margin-bottom: 4px;
}
</style>