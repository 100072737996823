<template>
  <div>
    <component
      v-for="(dialog, index) in dialogs.flat()"
      :key="`dialog${index}`"
      :is="dialog.component"
      v-model="dialog.model"
      :disabled="dialog.disabled()"
      :spaceUri="dialog.spaceUri || spaceUri"
      :spaceName="spaceName"
      :spaceKey="spaceKey"
      :belongsTo="belongsTo"
    />
    <v-menu offset-x v-model="menu">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          data-testid="showSpaceSettingsButton"
          color="grey"
          v-bind="attrs"
          @click.prevent="on.click"
          icon
          small
        >
          <v-icon>mdi-dots-horizontal-circle</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <template
          v-for="(dialog, index) in dialogs"
        >
          <MenuItem
            v-if="index < dialogs.length - 1"
            :key="`menuItem${index}`"
            :disabled="dialog.disabled()"
            v-bind="dialog.menuItemProps"
            @click="dialog.model = true"
          />
          <SubMenu
            v-else
            :activatorText="$t('dialogs.more')"
            :key="`subMenu${index}`"
          >
            <MenuItem
              v-for="(subDialog, index) in dialog"
              :key="`menuItem${index}`"
              :disabled="subDialog.disabled()"
              v-bind="subDialog.menuItemProps"
              @click="subDialog.model = true; menu = false"
            />
            <MenuItem
              @click="setURLClipboard(realSpaceUri || spaceUri)"
              icon="mdi-content-copy"
              :text="$t('dialogs.copyEntryUri')"
              data-testid="copyspaceUri"
            />
          </SubMenu>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import RenameSpaceDialog from '@/components/space/RenameSpaceDialog.vue'
import DeleteSpaceDialog from '@/components/space/DeleteSpaceDialog.vue'
// import ShareSpaceDialog from '@/components/space/ShareSpaceDialog.vue'
import CopySpaceDialog from '@/components/space/CopySpaceDialog.vue'
import SetSpaceKeyDialog from '@/components/space/SetSpaceKeyDialog.vue'
import SetSpaceBelongsToDialog from '@/components/space/SetSpaceBelongsToDialog.vue'
import MenuItem from '@/components/MenuItem.vue'
import SubMenu from '@/components/SubMenu.vue'
import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'
import SpaceIconPickerDialog from './SpaceIconPickerDialog.vue'

export default {
  name: 'SpaceSettingsMenu',
  props: {
    spaceUri: null,
    spaceName: null,
    realSpaceUri: null
  },
  data() {
    return {
      menu: false,
      loading: false,
      space: undefined,
      realSpace: undefined,
      dialogs: [
        // {
        //   model: false,
        //   disabled: () => !hasPermission(this.realSpace ?? this.space, [PERMISSIONS.addShare]),
        //   spaceUri: this.realSpaceUri ?? this.spaceUri,
        //   menuItemProps: {
        //     icon: 'mdi-account-multiple-plus',
        //     text: this.$t('dialogs.shareSpaceButton'),
        //     'data-testid': 'shareSpaceDialogActivator'
        //   },
        //   component: ShareSpaceDialog
        // },
        {
          model: false,
          disabled: () => !hasPermission(this.space, [PERMISSIONS.patch]),
          menuItemProps: {
            icon: 'mdi-pencil',
            text: this.$t('dialogs.renameSpaceButton'),
            'data-testid': 'renameSpaceActivator'
          },
          component: RenameSpaceDialog
        },
        {
          model: false,
          disabled: () => !hasPermission(this.space, [PERMISSIONS.patch]),
          menuItemProps: {
            icon: 'mdi-format-color-fill',
            text: this.$t('dialogs.spaceIcon.menuItem'),
            'data-testid': 'setSpaceIconActivator'
          },
          component: SpaceIconPickerDialog
        },
        {
          model: false,
          disabled: () => !hasPermission(this.space, [PERMISSIONS.copy]),
          menuItemProps: {
            icon: 'mdi-card-multiple',
            text: this.$t('dialogs.copySpaceButton'),
            'data-testid': 'copySpaceActivator'
          },
          component: CopySpaceDialog
        },
        {
          model: false,
          disabled: () => !hasPermission(this.space, [PERMISSIONS.remove]),
          menuItemProps: {
            icon: 'mdi-delete',
            text: this.$t('dialogs.deleteSpace'),
            'data-testid': 'showDeleteSpaceModalButton'
          },
          component: DeleteSpaceDialog
        },
        [
          {
            model: false,
            disabled: () => !hasPermission(this.space, [PERMISSIONS.patch]),
            menuItemProps: {
              icon: 'mdi-pound',
              text: this.$t('dialogs.setSpaceKeyButton'),
              'data-testid': 'setSpaceKeyActivator'
            },
            component: SetSpaceKeyDialog
          },
          {
            model: false,
            disabled: () => !hasPermission(this.space, [PERMISSIONS.patch]),
            menuItemProps: {
              icon: 'mdi-shape',
              text: this.$t('dialogs.setSpaceBelongsToButton'),
              'data-testid': 'setBelongsToActivator'
            },
            component: SetSpaceBelongsToDialog
          }
        ]
      ]
    }
  },
  watch: {
    menu: {
      immediate: true,
      async handler(newVal) {
        if (!newVal) {
          return
        }
        this.loading = true
        try {
          this.space = await this.$store.dispatch('AGReadSpaceOperation', {
            spaceUri: this.spaceUri,
            cached: true
          })
          if (this.realSpaceUri) {
            this.realSpace = await this.$store.dispatch('AGReadSpaceOperation', {
              spaceUri: this.realSpaceUri,
              cached: true
            })
          }
        } finally {
          this.loading = false
        }
      }
    }
  },
  computed: {
    spaceKey() {
      return this.space?.key
    },
    belongsTo() {
      return this.space?.belongsTo
    }
  },
  methods: {
    setURLClipboard(value) {
      navigator.clipboard.writeText(`${window.location.origin}${value}`)
    }
  },
  components: {
    MenuItem,
    SubMenu
  }
}
</script>

<style lang="css"></style>
