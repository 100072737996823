import ApiClient from '@/ApiClient'
import { columnTypes } from '@/constants/columnTypes'

export default {
  methods: {
    async loadResources() {
      this.loading = true
      try {
        const response = await ApiClient.getUri(this.field._links.resources.href)
        const allItems = response.data.map(item => ({
          name: columnTypes.resource.displayFormat(item),
          href: item.href,
          type: item.type
        }))
        const itemsByType = {}
        for (let item of allItems) {
          if (itemsByType[item.type] != null) {
            itemsByType[item.type].push(item)
          } else {
            itemsByType[item.type] = [item]
          }
        }
        const itemsWithHeaders = []
        for (let type of Object.keys(itemsByType)) {
          itemsWithHeaders.push({header: this.$t(`resourceTypes.${type}`)})
          for (let item of itemsByType[type]) {
            itemsWithHeaders.push(item)
          }
        }
        this.items = itemsWithHeaders
      } finally {
        this.loading = false
      }
    }
  }
}