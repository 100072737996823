<template>
  <div class="d-flex flex-column ma-6">
    <v-sheet rounded elevation="2" color="white" class="profile pa-10">

      <div class="text-h6">{{$t('payment.title')}}</div>
      <v-divider class="mt-2 mb-4"></v-divider>
      <div class="mt-5 mb-5 text-subtitle-1">
        {{$t('payment.billing.currentPlan')}}
        <span class="text-uppercase font-weight-bold">{{ plan }}</span>
      </div>
      <div class="mt-5 mb-5 text-subtitle-1">
        {{$t('payment.billing.explanation')}}
      </div>
     
      <v-btn :href="prefilledCustomerPortalLink" target="_blank" color="primary">
        {{$t('payment.billing.button')}}
        <v-icon class="ml-2" small>mdi-share</v-icon>
      </v-btn>
      <PlanList />

    </v-sheet>
  </div>
</template>

<script>

import PlanList from '@/components/paywall/PlanList.vue'

export default {
  computed: {
    user() {
      return this.$store.state.user?.user
    },
    prefilledCustomerPortalLink() {
      return `${this.$settings.payment.customerPortal}?prefilled_email=${this.user.email}`
    },
    plan() {
      return this.$store.getters.userPlan
    }
  },
  components: {
    PlanList
  }
}
</script>

<style lang="css" scoped>
</style>
