<template functional>
  <div
    class="pl-2 cell border-right d-flex align-center"
    :class="$options.classesFromData(data)"
    :style="data.style"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <span
      v-for="(item, index) in $options.valueArray(props.value)"
      :key="index"
      class="mr-1 flex-shrink-0 v-chip--select v-chip v-chip--no-color theme--light v-size--small font-weight-medium"
    >{{item}}</span>
  </div>
  </template>
  
  <script>
  import { columnTypes } from '@/constants/columnTypes'
  import { noValue } from '@/utils/jsUtils.js'
  import { classesFromData } from '@/utils/vueUtils.js'
  
  export default {
    props: ['value'],
    valueArray(value) {
      if(noValue(value)) {
        return []
      }
      const array = Array.isArray(value) ? value : [value]
      return array.map(value => columnTypes.resource.displayFormat(value))
    },
    classesFromData
  }
  </script>
  