import PersistentGrid from '../models/PersistentGrid.js'
import ApiClient from '@/ApiClient'
import Vue from 'vue'
import { PERMISSIONS } from '@/utils/halUtils.js'

export default {
  state() {
    return {
      grids: {}
    }
  },
  mutations: {
    addGrid(state, grid) {
      Vue.set(state.grids, grid.uri, grid)
    },
    removeGrid(state, gridUri) {
      Vue.set(state.grids, gridUri, null)
      delete state.grids[gridUri]
    }
  },
  actions: {
    AGLoadAllGridsOperation(context, spaceUri) {
      const space = context.getters.spaceWithUri(spaceUri)
      if (!space) {
        return []
      }
      const promise = new Promise(resolve => {
        const allPromises = []
        const grids = context.getters.gridsOfSpace(spaceUri)
        grids.forEach(grid => {
          allPromises.push(context.dispatch('AGReadGridOperation', grid.uri))
        })
        Promise.all(allPromises).then((grids) => {
          resolve(grids)
        })
      })
      return promise
    },
    async AGReadGridOperation(context, { uri, loadEntities = false}) {
      uri = uri ?? arguments[1]
      const gridResponse = await ApiClient.getUri(uri, undefined, { headers: {'Accept': 'application/vnd.apptivegrid.hal;version=2'}})
      const grid = new PersistentGrid(gridResponse.data)
      if (loadEntities) {
        await context.dispatch('AGListVirtualGridEntitiesOperation', grid)
      }
      else {
        const oldGrid = context.getters.gridWithUri(uri)
        grid.entities = oldGrid?.entities || []
      }
      context.commit('addGrid', grid)
      return grid
    },
    AGColumnAddOperation(context, { virtualGridUri, position, name, type, key }) {
      const grid = context.getters.gridWithUri(virtualGridUri)
      const link = grid.getLink('addField')
      return ApiClient.postUri(link, {
        position,
        name,
        type,
        key
      }).then(response => {
        const newFieldLocation = response.headers.location
        const split = newFieldLocation.split('/')
        return split[split.length - 1]
      })
    },
    async AGAddGridOperation(context, { spaceUri, newName }) {
      const space = context.getters.spaceWithUri(spaceUri)
      const addGridLink = space.getLink('addGrid')
      const response = await ApiClient.postUri(addGridLink, {
        name: newName
      })
      const uri = response.headers['location']
      context.dispatch('loadGridsUris', spaceUri)
      const grid = await context.dispatch('AGReadGridOperation', uri)
      await context.dispatch('AGAddSpreadsheetViewOperation', { grid })
      return grid
    },
    async AGCreateGridFromCsvOperation(context, { spaceUri, csvFile, hasHeader}) {
      const space = context.getters.spaceWithUri(spaceUri)
      const link = space.getLink('uploadCSV')
      const data = new FormData()
      data.append('config', new Blob([JSON.stringify({ hasHeader })], { type: 'application/json' }))
      data.append('csv', new Blob([csvFile], { type: 'text/csv' }))
      return ApiClient.postUri(
        link,
        data,
        undefined,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
      })
    },
    async AGDeleteGridOperation(context, gridUri) {
      const grid = context.getters.gridWithUri(gridUri)
      const link = grid.getLink('remove')
      const response = await ApiClient.deleteUri(link)
      const space = context.getters.spaceIncludesGridWithUri(gridUri)
      context.commit('removeGrid', gridUri)
      await context.dispatch('loadGridsUris', space.uri)
      return response
    },
    AGRenameGridOperation(context, { gridUri, newName }) {
      const grid = context.getters.gridWithUri(gridUri)
      const link = grid.getLink('rename')
      return ApiClient.postUri(link, {
        name: newName
      }).then(response => {
        const grid = context.getters.gridWithUri(gridUri)
        grid.name = newName
        const space = context.getters.spaceIncludesGridWithUri(gridUri)
        context.dispatch('loadGridsUris', space.uri)
        return response
      })
    },
    AGRenameColumnOperation(context, { grid, oldName, newName }) {
      const link = grid.getLink('updateFieldName')
      return ApiClient.postUri(link, {
        oldName: oldName,
        newName: newName
      })
    },
    AGUpdateFieldKeyOperation(context, { virtualGridUri, fieldId, newKey }) {
      const grid = context.getters.gridContainingVirtualGridWithUri(virtualGridUri)
      const link = grid.getLink(PERMISSIONS.updateFieldKey)
      return ApiClient.postUri(link, {
        fieldId,
        key: newKey
      })
    },
    AGColumnTypeChangeOperation(
      context,
      { grid, fieldId, newType }
    ) {
      const link = grid.getLink('updateFieldType')
      return ApiClient.postUri(link, {
        fieldId: fieldId,
        type: newType
      })
    },
    AGRemoveColumnOperation(context, { virtualGridUri, fieldId }) {
      const grid = context.getters.gridContainingVirtualGridWithUri(virtualGridUri)
      const link = grid.getLink(PERMISSIONS.removeField)
      return ApiClient.postUri(link, {
        fieldId
      })
    },
    AGHasUpdateSince(context, { gridUri, dateTime }) {
      const grid = context.getters.gridWithUri(gridUri)
      const link = grid.getLink('updates')
      return ApiClient.getUri(link, {since : dateTime.toISOString()})
    },
    AGSetGridKeyOperation(context, { gridUri, key }) {
      const grid = context.getters.gridWithUri(gridUri)
      const link = grid.getLink(PERMISSIONS.patch)
      return ApiClient.patchUri(link, { key })
    },
    async AGChangeGridPositionOperation(context, { space, gridUri, newPosition }) {
      const link = space.getLink(PERMISSIONS.changePersistentGridPosition)
      // backend Arrays are one-based
      return ApiClient.postUri(link, {
        position: newPosition + 1,
        uri: gridUri
      })
    }
  },
  getters: {
    grids: (state, getters, rootState, rootGetters) => space => {
      const grids = rootGetters.gridsOfSpace(space.uri).map(el => state.grids[el.uri])
      const filtered = grids.filter(grid => grid != null)
      return filtered
    },
    gridWithUri: (state, getters, rootState, rootGetters) => uri => {
      return state.grids[uri] ?? rootGetters.virtualGridWithUri(uri)
    },
    gridContainingVirtualGridWithUri: (state, getters, rootState, rootGetters) => viewUri => {
      const grids = Object.values(state.grids)
      const found = grids.find(grid =>
        rootGetters.viewsOfGrid(grid.uri).find(view => view.uri == viewUri)
      )
      return found
    },
    gridContainingFormUri: (state, getters, rootState, rootGetters) => formUri => {
      const grids = Object.values(state.grids)
      return grids.find(grid =>
        rootGetters.formsOfGrid(grid.uri).some(el => el.uri === formUri)
      )
    }
  }
}
