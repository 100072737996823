<template>
  <div class="pa-3" style="position: relative;">
    <LoadingOverlay :show="loading"/>
    <div class="pl-0 font-weight-medium subtitle-1 my-2">{{ $t('flow.nodes.requestTrigger.configuration.title') }}</div>

    <template v-if="showRedirect">
      <div class="pl-0">{{ $t('flow.nodes.requestTrigger.configuration.redirectDescription') }}</div>
      <LinkView
      class="my-4"
      :uri="redirectLink"
      showOpen
      />
      
      <div class="pl-0 text-pre-wrap">{{ $t('flow.nodes.requestTrigger.configuration.redirectTips') }}</div>

    </template>
    <template v-else>
    <div class="pl-0">{{ $t('flow.nodes.requestTrigger.configuration.description') }}</div>
    <LinkView class="my-4" :uri="link"/>

    <div class="pl-0 font-weight-medium subtitle-1 my-2 mt-8">{{ $t('flow.nodes.requestTrigger.configuration.postRequestTitle') }}</div>
    <div class="pl-0">{{ $t('flow.nodes.requestTrigger.configuration.postRequest') }}</div>

    <div class="pl-0 font-weight-medium subtitle-1 my-2">{{ $t('flow.nodes.requestTrigger.configuration.webhookTitle') }}</div>
    <div class="pl-0">{{ $t('flow.nodes.requestTrigger.configuration.webhook') }}</div>
  </template>
  </div>
</template>

<script>
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import LinkView from '../LinkView.vue'

export default {
  props: {
    flow: null,
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    link() {
      return this.flow?.trigger?.uri
    },
    redirectLink() {
      return this.flow?.trigger?.redirectUri
    },
    showRedirect() {
      if (this.flow == null) {
        return false
      }
      return this.flow.hasFlowFormTrigger()
    }
  },
  components: {
    LoadingOverlay,
    LinkView
  }
}
</script>