<template>
  <v-menu
    offset-y
    v-model="menu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="accent"
        v-show="!hideButton"
        v-bind="attrs"
        icon
        x-small
        @click.prevent="on.click"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list dense>
      <MenuItem
        :disabled="!canPatch"
        @click.stop="showRenameDialog"
        icon="mdi-pencil"
        :text="$t('blocks.renamePage')"
      />
      <MenuItem
        :disabled="!canPatch"
        @click.stop="showHeaderImageDialog"
        icon="mdi-panorama-variant"
        :text="$t('blocks.setHeaderImage')"
      />
      <MenuItem
        :disabled="!canDelete"
        @click.stop="deleteBlock"
        icon="mdi-delete"
        :text="$t('blocks.deletePage')"
      />
    </v-list>
    <SingleInputDialog
      v-model="headerImageDialog"
      :title="$t('blocks.setHeaderImage')"
      :okHandler="url => setHeaderImage(url)"
      :initialInputValue="headerImageUrl"
      max-width="290"
    />
    <SingleInputDialog
      v-model="renameDialog"
      :title="$t('blocks.renamePageTitle', {name: block.name})"
      :okHandler="newName => patchName(newName)"
      :initialInputValue="block.name"
      max-width="290"
    />
  </v-menu>
</template>

<script>
import MenuItem from '@/components/MenuItem.vue'
import SingleInputDialog from '@/components/SingleInputDialog.vue'
import { hasPermission, PERMISSIONS } from '@/utils/halUtils'

export default {
  props: {
    blockUri: {
      type: String
    },
    parentUri: {
      type: String
    },
    hideButton: {
      type: Boolean,
      default: () => false
    },
  },
  data() {
    return {
      menu: false,
      headerImageDialog: false,
      renameDialog: false
    }
  },
  computed: {
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    },
    parentBlock() {
      return this.$store.getters.blockWithUri(this.parentUri)
    },
    canPatch() {
      return hasPermission(this.block, [PERMISSIONS.patch])
    },
    canDelete() {
      return hasPermission(this.block, [PERMISSIONS.remove])
    },
    headerImageUrl() {
      return this.block?.headerImageUrl
    },
  },
  methods: {
    setHeaderImage(url) {
      this.block.setHeaderImageUrl(url)
    },
    async patchName(newName) {
      await this.block.patchName(newName)
      this.block.name = newName
    },
    showHeaderImageDialog() {
      this.headerImageDialog = true
    },
    showRenameDialog() {
      this.renameDialog = true
    },
    async deleteBlock() {
      this.$emit('deleteBlock')
    },
  },
  components: {
    MenuItem,
    SingleInputDialog
  }
}
</script>

<style
  lang="scss"
  scoped
></style>