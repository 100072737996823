<template>
  <div>
  <DefaultDialog
      v-model="externalModel"
      :title="$t('links.shareView.restrictions.title')"
      :text="$t('links.shareView.restrictions.text')"
      :items="items"
      :okHandler="updateViewHook"
      :maxWidth="400"
      
    >
      <v-select
        
        v-model="currentRestrictions"
        :items="items"
        solo outlined flat
        class="mx-6"
        data-testid="selectRestrictionSelect"
        
      >
        <template v-slot:item="{item, on, attrs}">
        
          <v-list-item style="max-width: 400px;" v-on="on" v-bind="attrs">
          <v-list-item-content :data-testid="item.testid">
            <v-list-item-title>{{item.text}}</v-list-item-title>
            <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        </template>
      </v-select>
      <v-form v-show="usernamePasswordSelected" ref="form" class="mx-6" v-model="valid" lazy-validation>
        <v-text-field :rules="usernameRules" dense filled v-model="username" :label="$t('links.shareView.restrictions.username')" />
        <v-text-field :rules="passwordRules" dense filled v-model="password" :label="$t('links.shareView.restrictions.password')" />
      </v-form>
    </DefaultDialog>
    <NewLinkCredentials v-model="newApiCredentialsDialog" :credentials="credentials" />
    
  </div>
</template>

<script>
import DefaultDialog from '@/components/DefaultDialog.vue'
import externalModel from '@/mixins/externalModel'
import NewLinkCredentials from '@/components/NewLinkCredentials.vue'

  export default {
    mixins: [externalModel],
    props: {
      link: null,
    },
    data() {
      return {
        currentRestrictions: undefined,
        newApiCredentialsDialog: false,
        valid: false,
        items: [
          {
            text: this.$t('links.shareView.restrictions.anyone'),
            description: this.$t('links.shareView.restrictions.anyoneDescription'),
            role: 'reader',
            requiresAuthorization: false,
            publicAccess: true,
            requiresPassword: false,
            testid: 'anyoneRestriction'
          },
          {
            text: this.$t('forms.shareForm.restrictions.onlyUsers'),
            description: this.$t('forms.shareForm.restrictions.onlyUsersDescription'),
            role: 'reader',
            requiresAuthorization: true,
            publicAccess: true,
            requiresPassword: false,
            testid: 'onlyUsersRestriction'
          },
          {
            text: this.$t('forms.shareForm.restrictions.onlyCollaborators'),
            description: this.$t('forms.shareForm.restrictions.onlyCollaboratorsDescription'),
            role: 'reader',
            requiresAuthorization: true,
            publicAccess: false,
            requiresPassword: false,
            testid: 'onlyCollaboratorsRestriction'
          },
          {
            text: this.$t('links.shareView.restrictions.usernamePassword'),
            description: this.$t('links.shareView.restrictions.usernamePasswordDescription'),
            role: 'reader',
            requiresAuthorization: false,
            publicAccess: true,
            requiresPassword: true,
            testid: 'usernamePasswordRestriction'
          },
        ],
        username: undefined,
        password: undefined,
        credentials: undefined,
        usernameRules: [
          v => !!v || 'Username is required',
        ],
        passwordRules: [
          v => !!v || 'Password is required',
        ],
      }
    },
    computed: {
      usernamePasswordSelected() {
        return this.currentRestrictions === this.items[3].text
      }
    },
    watch: {
      externalModel: {
        async handler(newVal) {
          if (!newVal || this.link == null) return
          const fullLink = await this.$store.dispatch('AGReadLinkOperation', this.link.getLink('self'))
          const item = this.items
            .find(item =>
              item.requiresPassword === fullLink.requiresPassword
              && item.publicAccess === fullLink.publicAccess
              && item.requiresAuthorization === fullLink.requiresAuthorization
            )
          this.currentRestrictions  = item.text
        }
      }
    },
    methods: {
      async updateViewHook() {
        if (this.usernamePasswordSelected && !this.$refs.form.validate()) {
          return
        }

        const item = this.items.find(item => item.text === this.currentRestrictions)
        let username = this.usernamePasswordSelected ? this.username : null
        let password = this.usernamePasswordSelected ? this.password : null
        this.loading = true
        try {
          await this.link.setRestrictions({
            role: item.role,
            requiresAuthorization: item.requiresAuthorization,
            publicAccess: item.publicAccess,
            requiresPassword: this.usernamePasswordSelected,
            username,
            password,
          })
          this.credentials = { username, password }
          this.newApiCredentialsDialog = this.usernamePasswordSelected
        } finally {
          this.loading = false
          this.externalModel = false
          this.$refs.form.reset()
        }
      }
    },
    components: { DefaultDialog, NewLinkCredentials },
}
</script>

<style lang="scss" scoped>

</style>