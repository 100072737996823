import { ASHolder } from '@/apptivescript/model/ASHolder'

export default class ASValueHolder extends ASHolder{
  constructor(data) {
    super()
    this._value = data._value
  }

  get value() {
    return this._value
  }
}