/* global ASParser ASMethodChainAnnotation ASAnnotationVisitor ASError*/
import { ExpressionVisitor } from '@/apptivescript/apptivescriptUtils'
import Editor from '@/editor/Editor'

export class ExpressionEditor extends Editor {

  constructor(...args) {
    super(...args)
    this.error = undefined
  }

  static tokenPropsFromString(expression, {grid, flow}) {
    const ast = ASParser.as_parse_(expression)
    const annotationVisitor = new ASAnnotationVisitor()
    const visitor = new ExpressionVisitor(grid, flow)
    const annotation = new ASMethodChainAnnotation()
    annotation.as_from_('step')
    annotation.as_to_('get')
    annotation.as_key_('replacement')
    annotation.as_value_('foo')
    annotationVisitor.as_addRule_(annotation)
    annotationVisitor.as_visit_(ast)
    visitor.as_visit_(ast)
    return visitor.tokens ?? []
  }

  setContentFromString(string, grid) {
    if (!string) {
      this.clearContent()
      return
    }
    let tokenPropsArray
    try {
      tokenPropsArray = this.constructor.tokenPropsFromString(string, {grid, flow: this.flow})
    } catch(error) {
      if (error instanceof ASError) {
        this.error = error.message || 'Invalid Expression'
      } else {
        console.error(error)
      }
      return
    }
    this.error = undefined
    this.clearContent()
    tokenPropsArray.forEach(token => this.insertToken(token))
  }

  initContentFromString(string, grid) {
    this.setContentFromString(string, grid)
    if (this.error) {
      this.clearContent()
      this.insertText(string)
    }
  }
}