<template>
<EntityPicker
  ref="input"
  v-model="model"
  :gridUri="gridUri"
  :isCollection="isCollection"
  :fieldName="field.name"
  bottom offset-y
  :readonly="readonly"
>
  <template v-slot:activator="{on}">
    <RoGridReferencesCell
      @click="on.click"
      :value="value"
      :class="{'fit-parent-size': true}"
      @focus="$emit('focus')"
      tabindex="0"
      @mouseenter.native="onMouseEnter"
      @mouseleave.native="onMouseLeave"
      v-bind="$attrs"
    />
  </template>
</EntityPicker>
</template>

<script>
import EntityPicker from './EntityPicker.vue'
import RoGridReferencesCell from './RoGridReferencesCell.vue'
import { gridCells } from '@/mixins/gridCells.js'

export default {
  inheritAttrs: false,
  mixins: [gridCells],
  data() {
    return {
      filter: '',
      showControls: false,
      showControlsTimeout: undefined
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal ?? null)
        this.$emit('blur')
      }
    },
    gridUri() {
      return this.field.type.gridUri
    },
    isCollection() {
      return this.field.type.jsonSchema.type === 'array'
    },
  },
  methods: {
    selectInput() {
      this.$refs.input.openMenu()
      this.$emit('focus')
    },
    onMouseEnter() {
      if (this.$vuetify.breakpoint.mobile) {
        // On mobile, prevent the user from accidentaly
        // clearing when selecting the cell
        this.showControlsTimeout = setTimeout(() => this.showControls = true, 100)
      } else {
        this.showControls = true
      }
    },
    onMouseLeave() {
      if (this.showControlsTimeout) {
        clearTimeout(this.showControlsTimeout)
      }
      this.showControls = false
    }
  },
  components: {
    EntityPicker,
    RoGridReferencesCell
}
}
</script>

<style scoped lang="scss">
.fit-parent-size {
  height: 100%;
  width: 100%;
}
</style>
