<template>
  <v-dialog v-model="externalModel" max-width="450">
    <v-card>
      <v-card-title>QrCode</v-card-title>
      <v-card-text>
        <div class="pt-4">{{ $t("forms.shareForm.tabs.qrCodeText") }}</div>
        <QRCode class="mt-3" :text="uri"/>
        <div v-if="showMobileApp">
        <div class="pt-4">{{ $t("forms.shareForm.tabs.openInAppExplanation") }}</div>
        <div class="d-flex flex-wrap justify-center align-center mt-3">
          <a href="https://apps.apple.com/us/app/apptivegrid-launcher/id1547415276" target="_blank">
            <img class="fit-parent-img mr-1" :src="$t('forms.shareForm.tabs.appStoreBadgeSrc')"/>
          </a>
          <a href="https://play.google.com/store/apps/details?id=de.zweidenker.zweidenker_forms" target="_blank">
            <img class="fit-parent-img ml-1" :src="$t('forms.shareForm.tabs.playStoreBadgeSrc')"/>
          </a>
        </div>
      </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import QRCode from '@/components/QRCode.vue'
import externalModel from '@/mixins/externalModel'

export default {
  mixins: [externalModel],
  props: {
    uri: null,
    showMobileApp: {
      type: Boolean,
      default: true
    }
  },
  components: {
    QRCode
  }
}
</script>

<style lang="scss" scoped>
.fit-parent-img {
  width: auto;
  height: 40px;
}
</style>